(function ($, window, document) {
  $(document).ready(function () {
    // maps
    let myMap1, myMap2, myMap3;
    function initMap1() {
      if (!myMap1) {
        myMap1 = new ymaps.Map("map1", {
          center: [45.379319, 35.923754],
          zoom: 11,
          controls: []
        }, {
          // searchControlProvider: 'yandex#search'
        });

        myMap1.geoObjects
          .add(new ymaps.Placemark([45.379319, 35.923754], {
            balloonContent: 'Курортный комплекс «Азовский»'
          }, {
            preset: 'islands#blueIcon',
          }));
      }
    }

    function initMap2() {
      if (!myMap2) {
        myMap2 = new ymaps.Map("map2", {
          center: [45.429026, 36.072784],
          zoom: 11,
          controls: []
        }, {
          // searchControlProvider: 'yandex#search'
        });

        myMap2.geoObjects
          .add(new ymaps.Placemark([45.429026, 36.072784], {
            balloonContent: 'Курортный комплекс «Азовский»'
          }, {
            preset: 'islands#blueIcon',
          }));
      }
    }

    function initMap3() {
      if (!myMap3) {
        myMap3 = new ymaps.Map("map3", {
          center: [45.379319, 35.923754],
          zoom: 11,
          controls: []
        }, {
          // searchControlProvider: 'yandex#search'
        });

        myMap3.geoObjects
          .add(new ymaps.Placemark([45.379319, 35.923754], {
            balloonContent: 'Курортный комплекс «Азовский»'
          }, {
            preset: 'islands#blueIcon',
          }));
      }
    }

    let myMapAll;
    function initMapAll() {
      if (!myMapAll) {
        myMapAll = new ymaps.Map("mapAll", {
          center: [45.379319, 35.923754],
          zoom: 11,
          controls: []
        }, {
          // searchControlProvider: 'yandex#search'
        });

        myMapAll.geoObjects
          .add(new ymaps.Placemark([45.429026, 36.072784], {
            balloonContent: 'Курортный комплекс «Азовский»'
          }, {
            preset: 'islands#blueIcon',
          }))
          .add(new ymaps.Placemark([45.379319, 35.923754], {
            balloonContent: 'Курортный комплекс «Азовский»'
          }, {
            preset: 'islands#blueIcon',
          }));
      }
    }

    $('.tabs__wrap').on('click', function () {
      //   let mapPosition = $("#map").offset().top - $("#map").height();
      if ($('#map1').parent('.tab-content.tab-map').hasClass('active')) {
        ymaps.ready(initMap1);
      }
      if ($('#map2').parent('.tab-content.tab-map').hasClass('active')) {
        ymaps.ready(initMap2);
      }
    });

    $(window).on('load resize', function () {
      if ($('.about-complex').length) {
        if ($('#map1').length) {
          ymaps.ready(initMap1);
        }
        if ($('#map2').length) {
          ymaps.ready(initMap2);
        }
      }

      if ($('.complex-location').length) {
        if ($('#map3').length) {
          ymaps.ready(initMap3);
        }
      }

      if ($('.how-to-get').length) {
        if ($('#mapAll').length) {
          ymaps.ready(initMapAll);
        }
      }
    })
  });
})(jQuery, window, document);