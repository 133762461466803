document.addEventListener("DOMContentLoaded", () => {
  'use strict';

  // lazyLoad
  [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
    img.setAttribute('src', img.getAttribute('data-src'));
    img.onload = function () {
      img.removeAttribute('data-src');
    };
  });

  const titleSpl = (classBlock, length) => {
    let blocks = document.querySelectorAll(classBlock);
    blocks.forEach(item => {
      let itemText = item.textContent;
      itemText = itemText.slice(0, length) + '...';
      item.textContent = itemText;
    })
  }

  titleSpl('.news-block__title', 56);

  const tabStart = () => {

    let tabsContent = document.querySelectorAll('.tab-content');
    const tabsWrap = document.querySelectorAll('.tabs');

    tabsContent.forEach(item => {
      item.style.position = 'absolute';
      if (item.classList.contains('active')) {
        let parent = item.parentNode;
        parent.style.height = item.offsetHeight + 'px';
      } else {
        item.classList.add('hide');
      }
    });

    tabsWrap.forEach(item => {
      const btnPrev = item.parentNode.querySelector('.tab-btn__prev'),
        btnNext = item.parentNode.querySelector('.tab-btn__next'),
        widthScroll = item.scrollWidth,
        widthClient = item.clientWidth,
        scroll = item.scrollLeft;
      if (widthScroll - widthClient > 0) {
        btnNext.classList.add('active');
      }
      if (scroll < 0) {
        btnPrev.classList.add('active');
      }

      const tabScrollLeft = (e) => {
        const t = e.target,
          block = t.closest('.tabs__top').querySelector('.tabs'),
          tabActive = block.querySelector('.tab.active');
        let tabPrevWidth = tabActive.previousElementSibling.offsetLeft,
          scrollTo = tabPrevWidth - 40;

        tabActive.previousElementSibling.click();

        const step = () => {
          let blockLeft = block.scrollLeft;
          if (scrollTo < blockLeft && blockLeft > 0) {
            block.scrollBy(-1, 0);
            requestAnimationFrame(step);
          }
        }

        step();
      };

      const tabScrollRight = (e) => {
        const t = e.target,
          block = t.closest('.tabs__top').querySelector('.tabs'),
          blockWidth = block.scrollWidth,
          tabActive = block.querySelector('.tab.active'),
          windowW = window.innerWidth;
        let tabNextWidth = tabActive.nextElementSibling.offsetLeft,
          scrollTo = tabNextWidth - 40,
          blockF = blockWidth - scrollTo;

        tabActive.nextElementSibling.click();

        const step = () => {
          let blockLeft = block.scrollLeft;
          if (scrollTo > blockLeft) {
            block.scrollBy(1, 0);
            requestAnimationFrame(step);
          }
        }

        if (blockF > windowW) {
          step();
        } else {
          scrollTo = (tabNextWidth - 40) - ((tabNextWidth - 40 + windowW) - blockWidth);
          step();
        }
      };

      btnPrev.addEventListener('click', tabScrollLeft);
      btnNext.addEventListener('click', tabScrollRight);
    });
  }

  const tab = () => {

    const tabsWrap = document.querySelectorAll('.tabs');
    const tabs = document.querySelectorAll('.tab');

    const tabScrollLeft = (tabs) => {
      const block = tabs,
        tabActive = block.querySelector('.tab.active');
      let tabWidth = tabActive.offsetLeft,
        scrollTo = tabWidth - 40;

      const step = () => {
        let blockLeft = block.scrollLeft;
        if (scrollTo < blockLeft && blockLeft > 0) {
          block.scrollBy(-1, 0);
          requestAnimationFrame(step);
        }
      }

      step();
    };

    const tabScrollRight = (tabs) => {
      const block = tabs,
        blockWidth = block.scrollWidth,
        tabActive = block.querySelector('.tab.active'),
        windowW = window.innerWidth;
      let tabWidth = tabActive.offsetLeft,
        scrollTo = tabWidth - 40,
        blockF = blockWidth - scrollTo;

      const step = () => {
        let blockLeft = block.scrollLeft;
        if (scrollTo > blockLeft) {
          block.scrollBy(1, 0);
          requestAnimationFrame(step);
        }
      }

      if (blockF > windowW) {
        step();
      } else {
        scrollTo = (tabWidth - 40) - ((tabWidth - 40 + windowW) - blockWidth);
        step();
      }
    };

    const tabOpen = (e) => {
      let targer = e.target.closest('.tab'),
        wrap = targer.closest('.tabs__wrap'),
        tabs = targer.closest('.tabs'),
        widthScroll = tabs.scrollWidth,
        widthScrollLeft = tabs.scrollLeft,
        widthClient = tabs.clientWidth,
        content = targer.dataset.tab,
        tabsBlock = wrap.querySelectorAll('.tab'),
        tabsContent = wrap.querySelectorAll('.tab-content');

      tabsBlock.forEach(item => {
        item.classList.remove('active');
      });

      targer.classList.add('active');
      if (widthScroll > window.innerWidth) {
        if (widthScrollLeft <= targer.offsetLeft) {
          tabScrollRight(tabs);
        } else {
          tabScrollLeft(tabs);
        }
      }

      tabsContent.forEach(item => {
        if (item.dataset.content !== content) {
          item.classList.remove('active');
          item.classList.add('hide');
        } else {
          item.classList.add('active');
          let parent = item.parentNode;
          parent.style.height = item.offsetHeight + 'px';
        }
      });
    };

    tabs.forEach(item => {
      item.addEventListener('click', tabOpen)
    });

    const tabScroll = (e) => {
      const targer = e.target,
        btnPrev = targer.parentNode.querySelector('.tab-btn__prev'),
        btnNext = targer.parentNode.querySelector('.tab-btn__next'),
        widthScroll = targer.scrollWidth,
        widthClient = targer.clientWidth,
        scroll = targer.scrollLeft,
        scrollNew = widthScroll - widthClient - scroll;

      if (scroll > 0) {
        btnPrev.classList.add('active');
      } else if (scroll === 0) {
        btnPrev.classList.remove('active');
      }

      if (scrollNew > 0) {
        btnNext.classList.add('active');
      } else if (scrollNew === 0) {
        btnNext.classList.remove('active');
      }

    };

    tabsWrap.forEach(item => item.addEventListener('scroll', tabScroll));
  }

  window.addEventListener('load', tabStart);
  window.addEventListener('resize', tabStart);
  tab();

  const btnUp = () => {
    const btn = document.querySelector('.btn_up');

    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const scrollUp = () => {
        window.scrollBy(0, -150);
        if (window.pageYOffset > 0) {
          requestAnimationFrame(scrollUp);
        }
      };
      scrollUp();
    });
  }

  btnUp();

  const popupImg = () => {
    const linksPopup = document.querySelectorAll('[data-popup]'),
      body = document.querySelector('body');

    let popup = document.createElement('div'),
      popupImgBlock = popup.cloneNode(),
      popupCloseBtn = popup.cloneNode(),
      popupImg = document.createElement('img');

    popup.classList.add('popup');
    popupImgBlock.classList.add('popup__img');
    popupCloseBtn.classList.add('popup__close');

    popupImgBlock.append(popupImg);
    popup.append(popupCloseBtn);
    popup.append(popupImgBlock);
    body.append(popup);

    const popupOpen = (e) => {
      e.preventDefault();
      let target = e.target,
        imgSrc = target.parentNode.getAttribute('href'),
        popupImg = document.querySelector('.popup__img img');

      popupImg.src = imgSrc;
      popup.classList.add('active')
    }

    const popupClose = (e) => {
      let target = e.target;

      if (!target.closest('.popup__img') || target.closest('.popup__close')) {
        popup.classList.remove('active');
      }

    }

    linksPopup.forEach(item => item.addEventListener('click', popupOpen));
    popup.addEventListener('click', popupClose);
  }

  popupImg();

  const aboutComplex = () => {
    const aboutComplexBlocks = document.querySelectorAll('.about-complex__block-wrap'),
      aboutComplexImg = document.querySelector('.about-complex__img'),
      aboutComplexLinkBottom = document.querySelector('.about-complex__bottom .link'),
      aboutComplexBtnOpen = document.querySelectorAll('.about-complex__block-wrap .link-btn');

    const openBlock = (e) => {
      const target = e.target,
        wrapBlock = target.closest('.about-complex__block-wrap'),
        hiddenBlock = wrapBlock.querySelectorAll('.hidden');

      if (!wrapBlock.matches('.active')) {
        wrapBlock.classList.add('active');
        hiddenBlock.forEach(item => {
          item.style.maxHeight = item.scrollHeight + 'px';
        });
        target.textContent = 'СКРЫТЬ';
      } else {
        wrapBlock.classList.remove('active');
        hiddenBlock.forEach(item => item.style.maxHeight = '');
        target.textContent = ' подробнее';
      }
    };

    if (aboutComplexBlocks.length && window.innerWidth < 768) {
      aboutComplexBlocks[aboutComplexBlocks.length - 1].querySelector('.link-btn').before(aboutComplexImg);
      aboutComplexBlocks[aboutComplexBlocks.length - 1].querySelector('.link-btn').before(aboutComplexLinkBottom);
      aboutComplexBtnOpen.forEach(item => item.addEventListener('click', openBlock));
    }

  }

  aboutComplex();

  const innerText = () => {
    const innerTextBlocks = document.querySelectorAll('.inner__text-wrap'),
      innerTextBtnOpen = document.querySelectorAll('.inner__text-wrap .link-btn');

    const openBlock = (e) => {
      const target = e.target,
        wrapBlock = target.closest('.inner__text-wrap'),
        hiddenBlock = wrapBlock.querySelectorAll('.hidden');

      if (!wrapBlock.matches('.active')) {
        wrapBlock.classList.add('active');
        hiddenBlock.forEach(item => {
          item.style.maxHeight = item.scrollHeight + 'px';
        });
        target.textContent = 'СКРЫТЬ';
      } else {
        wrapBlock.classList.remove('active');
        hiddenBlock.forEach(item => item.style.maxHeight = '');
        if (wrapBlock.querySelector('.action-booking__info-bottom')) {
          target.textContent = 'Подробнее об условиях акции';
        } else if (wrapBlock.matches('.info-block__main')) {
          target.textContent = 'подробнее';
        } else {
          target.textContent = 'читать';
        }
      }
    };

    if (innerTextBlocks.length && window.innerWidth < 768) {
      innerTextBtnOpen.forEach(item => item.addEventListener('click', openBlock));
    }

  }

  innerText();

  const complexShema = () => {
    const complexShemaWrap = document.querySelector('.shema__wrap');
    if (complexShemaWrap) {
      const shemaPoints = complexShemaWrap.querySelectorAll('.shema-point'),
        shemaBlock = complexShemaWrap.querySelector('.shema-block');

      document.addEventListener('click', (e) => {
        const target = e.target;
        if (target.matches('.shema-point') && !target.matches('.active')) {
          shemaPoints.forEach(item => item.classList.remove('active'));
          target.classList.add('active');
        } else {
          shemaPoints.forEach(item => item.classList.remove('active'));
        }
      });

      const btnPrev = complexShemaWrap.querySelector('.shema-arrow__prev'),
        btnNext = complexShemaWrap.querySelector('.shema-arrow__next'),
        widthScroll = shemaBlock.scrollWidth,
        widthClient = shemaBlock.clientWidth,
        scrollLeft = shemaBlock.scrollLeft;

      if (widthScroll - widthClient > 0) {
        btnNext.classList.add('active');
      }

      if (scrollLeft < 0) {
        btnPrev.classList.add('active');
      }

      const shemaScroll = (e) => {
        const targer = e.target,
          widthScroll = targer.scrollWidth,
          widthClient = targer.clientWidth,
          scroll = targer.scrollLeft,
          scrollNew = widthScroll - widthClient - scroll;

        if (scroll > 0) {
          btnPrev.classList.add('active');
        } else if (scroll === 0) {
          btnPrev.classList.remove('active');
        }

        if (scrollNew > 0) {
          btnNext.classList.add('active');
        } else if (scrollNew === 0) {
          btnNext.classList.remove('active');
        }

      };

      shemaBlock.addEventListener('scroll', shemaScroll);

      const shemaScrollLeft = (e) => {
        const t = e.target,
          block = t.closest('.shema__wrap').querySelector('.shema-block');

        let time = 100;
        const step = () => {
          let blockLeft = block.scrollLeft;
          if (blockLeft > 0 && time > 0) {
            block.scrollBy(-2, 0);
            time--;
            requestAnimationFrame(step);
          }
        }

        step();
      };

      const shemaScrollRight = (e) => {
        const t = e.target,
          block = t.closest('.shema__wrap').querySelector('.shema-block'),
          blockWidth = block.scrollWidth,
          windowW = window.innerWidth;

        let time = 100;
        const step = () => {
          let blockLeft = block.scrollLeft;
          if (blockLeft < blockWidth - windowW && time > 0) {
            block.scrollBy(2, 0);
            time--;
            requestAnimationFrame(step);
          }
        }

        step();
      };

      btnPrev.addEventListener('click', shemaScrollLeft);
      btnNext.addEventListener('click', shemaScrollRight);
    }
  }

  complexShema();

  const btnTopDisplay = () => {
    if (document.querySelector('.main-slider') && window.innerWidth <= 1100 && window.innerWidth > 767) {
      document.querySelector('.btn_top').style.display = 'none'
    } else {
      document.querySelector('.btn_top').style.display = ''
    }
  }

  window.addEventListener('load', btnTopDisplay);
  window.addEventListener('resize', btnTopDisplay);

  const scrollBlock = (btn) => {
    let href = btn.getAttribute('href'),
      blockForScroll = document.querySelector(href),
      topBlock = blockForScroll.offsetTop;

    const scrollDown = () => {
      window.scrollBy(0, 40);
      if (window.pageYOffset < topBlock - 50) {
        requestAnimationFrame(scrollDown);
      }
    };

    scrollDown();
  };

  const btnLink = document.querySelectorAll('.btn-link');

  if (btnLink.length) {
    btnLink.forEach(item => item.addEventListener('click', (e) => {
      e.preventDefault();
      scrollBlock(e.target);
    }));
  }

  const hiddenText = () => {
    const blocks = document.querySelectorAll('.how-to-get__block'),
      btnOpen = document.querySelectorAll('.how-to-get__block .link-btn');
    console.log('btnOpen: ', btnOpen);

    const openBlock = (e) => {
      const target = e.target,
        wrapBlock = target.closest('.how-to-get__block');
      let hiddenBlock;

      if (window.innerWidth > 768) {
        hiddenBlock = wrapBlock.querySelectorAll('.hidden');
      } else {
        hiddenBlock = wrapBlock.querySelectorAll('[class*="hidden"]');
      }
      console.log('hiddenBlock: ', hiddenBlock);

      if (!wrapBlock.matches('.active')) {
        wrapBlock.classList.add('active');
        hiddenBlock.forEach(item => {
          item.style.maxHeight = item.scrollHeight + 'px';
        });
        target.textContent = 'СКРЫТЬ';
      } else {
        wrapBlock.classList.remove('active');
        hiddenBlock.forEach(item => item.style.maxHeight = '');
        target.textContent = 'узнать подробнее';
      }
    };

    if (blocks.length) {
      btnOpen.forEach(item => item.addEventListener('click', openBlock));
    }

  }

  hiddenText();

});